import React, { useState } from "react";
import styles from './landing.module.css';
import AppleLogo from './AppleLogo';
import PlayStoreLogo from './PlayStoreLogo';
import MailingListPopup from "../misc/MailingListPopup";
import CountdownTimer from "../misc/Countdown";


// HeaderText Component - For the main title and subtitle
const HeaderText: React.FC = () => {
  return (
    <div className={styles.headerText}>
      <h1>A companion for your Emotional Health Journey.</h1>
    </div>
  );
};

// Sponsors Component - For sponsor logos
const Sponsors: React.FC = () => {
  return (
    <div className={styles.sponsorsContainer}>
      <img
        className={`${styles.sponsor} ${styles.nvidia}`}
        src="/images/banner/nvidia.png"
        alt="NVIDIA"
      />
      <img
        className={`${styles.sponsor} ${styles.foundersHub}`}
        src="/images/banner/founders-hub.png"
        alt="Founders Hub"
      />
    </div>
  );
};

// HeaderSubtext Component
const HeaderSubtext: React.FC = () => {
  return (
    <div className={styles.headerSubtext}>
      E.M.A. is an emotional health app that helps you track your emotions over time
    </div>
  );
};

// Define props for FeatureCard
interface FeatureCardProps {
  cls: string;
  icon: string;
  title: string;
  description: string;
  onClick?: () => void;
}

// FeatureCard Component - For the small card sections at the bottom
/*
const FeatureCard: React.FC<FeatureCardProps> = ({ cls, icon, title, description }) => {
  return (
    <div className={`${styles.featureCard} ${styles[cls]}`}>
      <img src={icon} alt={title} className={styles.icon} />
      <div className={styles.content}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};*/
const FeatureCard: React.FC<FeatureCardProps> = ({ cls, icon, title, description, onClick }) => {
  return (
    <div
      className={`${styles.featureCard} ${styles[cls]}`}
      onClick={onClick} // Trigger the click handler
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <img src={icon} alt={title} className={styles.icon} />
      <div className={styles.content}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};



// App Store Banner Component
interface AppStoreBannerProps {
  storeType: 'apple' | 'android';
}

/*
const AppStoreBanner: React.FC<AppStoreBannerProps> = ({ storeType }) => {
  const storeData = {
    apple: {
      bannerText: 'Start growing your Emotional health at the Apple App Store',
      logo: <AppleLogo className={`${styles.button} ${styles.logo} apple`}/>,
      url: 'https://apps.apple.com/us/app/e-m-a/id6499440724',
    },
    android: {
      bannerText: 'Start growing your Emotional health at the Google Play Store',
      logo: <PlayStoreLogo className={`${styles.button} ${styles.logo} android`}/>,
      url: '#',
    },
  };

  const { logo, bannerText, url } = storeData[storeType];

  return (
    <div className={`${styles.appStoreBanner} ${styles[storeType]}`}>
      {logo}
      <div className={styles.bannerText}>
        {bannerText}
      </div>
    </div>
  );
};
*/

const AppStoreBanner: React.FC<AppStoreBannerProps> = ({ storeType }) => {
  const [showPopup, setShowPopup] = useState(false);

  const storeData = {
    apple: {
      bannerText: 'Start growing your Emotional health at the Apple App Store',
      logo: <AppleLogo className={`${styles.button} ${styles.logo} apple`} />,
      url: 'https://apps.apple.com/us/app/e-m-a/id6499440724',
    },
    android: {
      bannerText: 'Start growing your Emotional health at the Google Play Store',
      logo: <PlayStoreLogo className={`${styles.button} ${styles.logo} android`} />,
      url: null, // No URL for Android
    },
  };

  const { logo, bannerText, url } = storeData[storeType];

  const handleClick = () => {
    if (storeType === 'android') {
      setShowPopup(true);
    } else if (url) {
      window.open(url, '_blank');
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div
        className={`${styles.appStoreBanner} ${styles[storeType]}`}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
      >
        {logo}
        <div className={styles.bannerText}>
          {bannerText}
        </div>
      </div>

      {showPopup && (
        <div className={styles.comingSoonPopupOverlay} onClick={closePopup}>
          <div className={styles.comingSoonPopupContent}>
            <p>Check back, Android Release Coming Soon</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

// AppStores Component
const AppStores: React.FC = () => {
  return (
    <div className={styles.appStoresContainer}>
      <AppStoreBanner storeType="apple" />
      <AppStoreBanner storeType="android" />
      <div className={styles.troubleText}>
        Trouble downloading it to your device? See our <a href="/contact">help page</a>
      </div>
    </div>
  );
};

// Main LandingContainer Component - Combines all the components
const LandingContainer: React.FC = () => {
  const [isMailingListPopupOpen, setIsMailingListPopupOpen] = useState(false);

  const handleOpenMailingListPopup = () => {
    setIsMailingListPopupOpen(true);
  };

  const handleCloseMailingListPopup = () => {
    setIsMailingListPopupOpen(false);
  };

  return (
    <>
      <div className={styles.landingContainer}>
        <div className={styles.topContainer}>
          <HeaderText />
          <HeaderSubtext />
          <AppStores />
          <Sponsors />
        </div>
        <div className={styles.featuresContainer}>
          <FeatureCard
            cls="ios"
            icon="/images/ios-logo.png"
            title="Beta App"
            description="Excuse our dust while we work through our Beta launch!"
          />
          <FeatureCard
            cls="android"
            icon="/images/subscribe.png"
            title="Sign Up for E.M.A. Updates"
            description="To get info on our E.M.A. launch and updates, click here to sign up for our newsletter."
            onClick={handleOpenMailingListPopup}
          />
        </div>
      </div>
  
      <div className={styles.bottomContainer}>
        <CountdownTimer />
      </div>
  
      {isMailingListPopupOpen && (
        <MailingListPopup onClose={handleCloseMailingListPopup} />
      )}
    </>
  );
};

export default LandingContainer;
